enum TRAVEL_STYLE_NAME {
  croisiere = 'Croisière',
  voyage_sur_mesure = 'Voyage sur mesure',
  safari = 'Safari',
  extension = 'Extension',
  autotour = 'Roadtrip & Autotour',
  circuit_accompagne = 'Circuit accompagné',
  sejour = 'Séjour',
  excursion = 'Excursion'
}

export default TRAVEL_STYLE_NAME;
